import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards01 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/Ethernet_and_Audio-Out/SelectCards01';
import SelectCards02 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/Ethernet_and_Audio-Out/SelectCards02';
import NavButtons from 'components/Outdoor_Cameras/IN-9408_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9408 WQHD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/Ethernet_and_Audio-Out/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9408 WQHD Quick Installation",
  "image": "../../P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9408 WQHD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9408 WQHD Quick Installation' image='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9408HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9408_WQHD/Schnell_Installation/Ethernet_and_Audio-Out/' locationFR='/fr/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/Ethernet_and_Audio-Out/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <Link to="/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Return to Section I</EuiButton></Link>
    <h2 {...{
      "id": "iv-a-power-ethernet--audio-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-a-power-ethernet--audio-cable",
        "aria-label": "iv a power ethernet  audio cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-a Power, Ethernet & Audio Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/99072/IN-9008-Quick-Installation-IV-A.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVUlEQVQoz52S0XLjIAxF/f//mMlOTDa1Y4OQECDAcicmzUPb6XT3PgodNFdXg6oCgHMu57z/o4Z931NKIYTW2v/AMUYiKqX8BlDV1pqqPmER+f3kEMI0TbXWJ6wf2j70M4yIvWd4VXPOIlJKyTn/wIvI63V4uBVptUbmUkqtNaUUY+zf9b5aq6r2uoh0g9u2DdfrNSAVZlrX+9ubO7QeMsacTqdxHM/nMxEZYy6XS4yxlKKqIjJYawFAmGFZpmm6LwsjovfGGO/9YxaAW9ZSChHdbjdmBgBrLREN3ntmrikhAHrPgeNqi0gP3znH3u/HbnuoIQQAWJblMblXS60iEmOMzALQaiWiWuvW2t/zH5zvvS2lhIjOuefCetzdQ0qJEMM055QC83Y8zePo57l3t9aYH7Y6MnxKwlq7HHA/A1UlRA/w7Ql9hpk5xoiIr2ttrfWovsLvja8yQIWo+wkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/e4706/IN-9008-Quick-Installation-IV-A.avif 230w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/d1af7/IN-9008-Quick-Installation-IV-A.avif 460w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/899b7/IN-9008-Quick-Installation-IV-A.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/a0b58/IN-9008-Quick-Installation-IV-A.webp 230w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/bc10c/IN-9008-Quick-Installation-IV-A.webp 460w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/6366e/IN-9008-Quick-Installation-IV-A.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/81c8e/IN-9008-Quick-Installation-IV-A.png 230w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/08a84/IN-9008-Quick-Installation-IV-A.png 460w", "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/99072/IN-9008-Quick-Installation-IV-A.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1a1ac421d1a52a428a32e0d8486d8b2f/99072/IN-9008-Quick-Installation-IV-A.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Thread the power, Ethernet and audio cable through the wall mount, screw cap, 3-holed sealing ring and the back plate of your camera.`}</p>
    <h2 {...{
      "id": "iv-b-sealing-ring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-b-sealing-ring",
        "aria-label": "iv b sealing ring permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-b Sealing Ring`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/99072/IN-9008-Quick-Installation-IV-B.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz3WTybLiMAxF+f8/YkmxZcGOHUkFyjGZHHmS53S9KORRdPfZOb7S1eAcYozTNPV9DwDjOHZdR0et9bKCiPSRbsc3IYRDKcV7j4jee+ccIuoV7z0F55ydcyEEEvg3OecD5WaMNU3DGHPOUUxZWd5473eNMYYEmzPn/Hg8tm1bSsk5Lx8opaSUpDmdTsMwhBDoagsGgMvlwhijgkMIe4phGMZxREQAuF6vnPO+77fgZVmcc4wxa+08zwDwZZ5XnHNt2yqlhBAAsJW9iz47/CdfU/hxjjFqra21iKiUMsZYaz9FMUYpJWmIfaiHEIKUEgC01vM8k+4zfQhhmiYhhFIKVmjaW89ESimspJT+Vzb1/1t2KSWlFGPsuu5+v1dVNQzD3zHGmMfjUVXV8/m01m4DQ0TOuXMuxng+n2+325e5EIL2/Hq9mqZRSu3mP8FCCBpDXddN09R1vT8D2jMA0J7neeacK6V+V0WF0f+QUjLGfE6bNkTPU0ppjEFEcv4DDG8obB86F0sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5657b23eb10dbc22f052fc5eec9f5e46/e4706/IN-9008-Quick-Installation-IV-B.avif 230w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/d1af7/IN-9008-Quick-Installation-IV-B.avif 460w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/899b7/IN-9008-Quick-Installation-IV-B.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5657b23eb10dbc22f052fc5eec9f5e46/a0b58/IN-9008-Quick-Installation-IV-B.webp 230w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/bc10c/IN-9008-Quick-Installation-IV-B.webp 460w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/6366e/IN-9008-Quick-Installation-IV-B.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5657b23eb10dbc22f052fc5eec9f5e46/81c8e/IN-9008-Quick-Installation-IV-B.png 230w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/08a84/IN-9008-Quick-Installation-IV-B.png 460w", "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/99072/IN-9008-Quick-Installation-IV-B.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5657b23eb10dbc22f052fc5eec9f5e46/99072/IN-9008-Quick-Installation-IV-B.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Loosen plastic mount for the sealing ring in the middle of the back plate. Press the sealing ring into its mount and fasten it with the screw cap.`}</p>
    <h2 {...{
      "id": "iv-c-plug-in-all-cables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-c-plug-in-all-cables",
        "aria-label": "iv c plug in all cables permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-c Plug in all Cables`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be42e794954fffd6ed61236e873d4543/99072/IN-9008-Quick-Installation-IV-C.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVElEQVQoz6WSy24bMQxF5///0I1hd4B0oRnZiF58SaSKkRpjNg2C9iwlUvfyUouq1lq1/cFU+yeqKiLznJlFhAcyMLOFiH68vd3v95/rer1e3ballMys946Il8vldrut67pt2+PxcM7t++69391WW11675Ay5EwAiJhDjDG+xJkZxnk/Y9Zr7b0fzcaiIsp8CIr0vzMdmaoyH81mRikLogAwUQU4l+qIwE6oWVftMpQPYzFVokbERHjyXGstpcQYSyn4CQBAzuXjQ80WRKScc4wlJWZ+7HtrbQrWWpm5lEJEPHxOFznlzTlmPtKOIfx6f38+nykl733OeZbO3cxXUkpnR2koLQAQY/TehxCYeRqbwYgIIoYQAOC18GnHOYeIyxghAwARlVJkpF1rNbPWGiLOgYnoNXNrjYhEZBGReXEuIqIp/jXLK5jzB5zK32r+Z/6r+TerqDOFH4KZVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be42e794954fffd6ed61236e873d4543/e4706/IN-9008-Quick-Installation-IV-C.avif 230w", "/en/static/be42e794954fffd6ed61236e873d4543/d1af7/IN-9008-Quick-Installation-IV-C.avif 460w", "/en/static/be42e794954fffd6ed61236e873d4543/899b7/IN-9008-Quick-Installation-IV-C.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be42e794954fffd6ed61236e873d4543/a0b58/IN-9008-Quick-Installation-IV-C.webp 230w", "/en/static/be42e794954fffd6ed61236e873d4543/bc10c/IN-9008-Quick-Installation-IV-C.webp 460w", "/en/static/be42e794954fffd6ed61236e873d4543/6366e/IN-9008-Quick-Installation-IV-C.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be42e794954fffd6ed61236e873d4543/81c8e/IN-9008-Quick-Installation-IV-C.png 230w", "/en/static/be42e794954fffd6ed61236e873d4543/08a84/IN-9008-Quick-Installation-IV-C.png 460w", "/en/static/be42e794954fffd6ed61236e873d4543/99072/IN-9008-Quick-Installation-IV-C.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be42e794954fffd6ed61236e873d4543/99072/IN-9008-Quick-Installation-IV-C.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Plug in the Power Supply, Ethernet and Audio Cable and Antenna Cable (if previously removed)`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards01 mdxType="SelectCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iv-h-internet-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-h-internet-access",
        "aria-label": "iv h internet access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-h Internet Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz5WRMUsDMRiG465ODk4OjlIUXEUQcRD8A+JPEEdB0MEOCqJgf4EKjvobFMRBFIoiKii9WrXXS3I52ssll0u8JNIWsUpr9eVZ8pE33/e9AQMr5xO5/OjWVWbzcjh7MZnL9y+fgcWTnqVT0JXB7M147mFs92Fk+25o4zazcz+wftO7et231h0wd1iZP8ILx/7sIZw+8Kb2K9N77sz+nwAwqD1XcLGMSi58g/4bJCK1SQux0p0A70oySlkU0TDkjLEokolokohYykRrbTsIaK1jzkkQYJ9UqzUhBEKo9PLqebBcdiFCQsRKqbZPAGOM1hph7PskSaS1lhC/UHCkUsaYNE1VQ7+ZIUTVWq1ZklIKIUznab/M1lglpVMshmEYUoowdl23bjam9d6P47fOPiEehDRi1togCBzHQQjzeoCsEWLEOW9n1toaE4aUECKSxFobc+55HsaYUso+1X7n+thKPj49McbsP1X/qjRNKaVCCK11c4umTDeBv6TaqfMHPlPbzBGNPJIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/e4706/IN-9008-Quick-Installation-1-16.avif 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/d1af7/IN-9008-Quick-Installation-1-16.avif 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/899b7/IN-9008-Quick-Installation-1-16.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/a0b58/IN-9008-Quick-Installation-1-16.webp 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/bc10c/IN-9008-Quick-Installation-1-16.webp 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/6366e/IN-9008-Quick-Installation-1-16.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/81c8e/IN-9008-Quick-Installation-1-16.png 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/08a84/IN-9008-Quick-Installation-1-16.png 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You need to `}<Link to="/Internet_Access/Port_Forwarding/" mdxType="Link">{`Forward a Port`}</Link>{` inside your router, if you want to access your camera over its `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS Address`}</Link>{` from the internet. Alternatively, you can use the `}<Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/" mdxType="Link">{`P2P User ID`}</Link>{` to access your camera with our smartphone app (see Step 12 below).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards02 mdxType="SelectCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iv-k-reassemble",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-k-reassemble",
        "aria-label": "iv k reassemble permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-k Reassemble`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c686ef73fb3f46874766b675492623bf/99072/IN-9008-Quick-Installation-IV-K.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNUlEQVQoz6VSy47DIAzM/39hb20PaUUIBowxkPCoEnaraLurVdW5GTwez8hDCAEAtNZSSmZu72BY15WZvfeIGGN8j9w+wGfkvKOUwsyImFJqrZUdOecf3TFGZn6+b5579zzP4zheLhciut1up9NJCNGbiEhK6Zwjoh5Q52/krqOUEkJcr1djjFLqfD4DADMT0TiO0zQRkbVWKZVS6tEOpRStNQAg4ra5texZCDEJMSvVTTnnAGCaJkS83++IuCzLV2ClFO99r1utrZSUUu7ljnVdZylBKeZtrtb6j7R3cttH/vLVGjN3O977jZxzttYaYxDRGKMBQgi99am8pLTEuHwHREQhhGGfWNcDcs61lKMyIlpjyZEx5niFw8tqNcQYmANvWA7O/7+wWmv3w8zOua5Td7ySH/m+NBaZpJE6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c686ef73fb3f46874766b675492623bf/e4706/IN-9008-Quick-Installation-IV-K.avif 230w", "/en/static/c686ef73fb3f46874766b675492623bf/d1af7/IN-9008-Quick-Installation-IV-K.avif 460w", "/en/static/c686ef73fb3f46874766b675492623bf/899b7/IN-9008-Quick-Installation-IV-K.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c686ef73fb3f46874766b675492623bf/a0b58/IN-9008-Quick-Installation-IV-K.webp 230w", "/en/static/c686ef73fb3f46874766b675492623bf/bc10c/IN-9008-Quick-Installation-IV-K.webp 460w", "/en/static/c686ef73fb3f46874766b675492623bf/6366e/IN-9008-Quick-Installation-IV-K.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c686ef73fb3f46874766b675492623bf/81c8e/IN-9008-Quick-Installation-IV-K.png 230w", "/en/static/c686ef73fb3f46874766b675492623bf/08a84/IN-9008-Quick-Installation-IV-K.png 460w", "/en/static/c686ef73fb3f46874766b675492623bf/99072/IN-9008-Quick-Installation-IV-K.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c686ef73fb3f46874766b675492623bf/99072/IN-9008-Quick-Installation-IV-K.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Fasten the mainboard slide and use the 4 screws to mount the back plate back onto your camera casing. Screw the wall mount to the back of the camera.`}</p>
    <h2 {...{
      "id": "iv-l-final-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iv-l-final-installation",
        "aria-label": "iv l final installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IV-l Final Installation`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89fc1b479b109e2618f9a73ef754e3af/99072/IN-9008-Quick-Installation-IV-L.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVQoz6WS4a7CIAyF9/7PqYmMje2WQks78EZq0Ki5Mbnfr8E47SmHKee8rmsIYVmWubNt2/U7plorEZUOMxMRM38rvv6Dz+LjOFpr7/uqWkSI+dr/fhYzc631fZ+IENE591dnEfkoZuacMyK20bk9UTsiMpYvRYnoMfNxHKp6dEQEAEIIzjkAQEQAeD5t0Vi/Wuukqs+FAeB8Pp9OpxCCxcbMIjLEiGgfpZRb5+FNVWMnd7z38zybflSPMaaUEDGlNLXWRCTnbPPEGJ1z3vtlWVJK3nsAGO6YGREvl8u6rjexDWCtzCcixhhLKTln59y+78OX+Sciu6l7VK017bxkU0rZ9/2nY1dohkMI27Y9crZs3rO1uvb4VdXOWDS/ZiA2WnK/GFsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89fc1b479b109e2618f9a73ef754e3af/e4706/IN-9008-Quick-Installation-IV-L.avif 230w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/d1af7/IN-9008-Quick-Installation-IV-L.avif 460w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/899b7/IN-9008-Quick-Installation-IV-L.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89fc1b479b109e2618f9a73ef754e3af/a0b58/IN-9008-Quick-Installation-IV-L.webp 230w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/bc10c/IN-9008-Quick-Installation-IV-L.webp 460w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/6366e/IN-9008-Quick-Installation-IV-L.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89fc1b479b109e2618f9a73ef754e3af/81c8e/IN-9008-Quick-Installation-IV-L.png 230w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/08a84/IN-9008-Quick-Installation-IV-L.png 460w", "/en/static/89fc1b479b109e2618f9a73ef754e3af/99072/IN-9008-Quick-Installation-IV-L.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89fc1b479b109e2618f9a73ef754e3af/99072/IN-9008-Quick-Installation-IV-L.png",
              "alt": "IN-9408 WQHD Schnell Installation",
              "title": "IN-9408 WQHD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Fasten the mainboard slide and use the 4 screws to mount the back plate back onto your camera casing. Screw the wall mount to the back of the camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      